import React from "react";
import { Pagination } from "antd";
export default function MyPagination(props) {
  const { page, total, pageSize,onPagination } = props;
  const onChange = (value)=>{
    onPagination(value);
  }
  return (
    <div style={{textAlign:'end',padding:'20px 16px 20px 16px'}}>
      <Pagination onChange={onChange} current={page} pageSize={pageSize} total={total}></Pagination>
    </div>
  );
}
