export const createColumn = (title, dataIndex, key, index,isActive = false, render, align= 'left') => {
  return {
    title,
    dataIndex,
    key,
    index,
    isActive, 
    render,
    align,
  };
};
export const createColumnNoActive = (title, dataIndex, key, render,align = "left") => {
  return {
    title,
    dataIndex,
    key,
    render,
    align,
  };
};

export const  cc_format = (value = '') => {
  var parts = []

  for (let i=0, len=value.length; i<len; i+=4) {
      parts.push(value.substring(i, i+4))
  }

  if (parts.length) {
      return parts.join(' ')
  } else {
      return value
  }
}
