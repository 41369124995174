import { createSlice } from "@reduxjs/toolkit";
import { getCard } from "./actions";
const initialState = {
  record: {},
  keyword: "",
  loading:  false
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder
     .addCase(getCard.pending, (state) => {
      state.loading = true;
    })
    .addCase(getCard.fulfilled, (state, action) => {
      state.loading = false;
      state.record =  action.payload.record;
      state.keyword = action.payload.keyword;
    })
    .addCase(getCard.rejected, (state, action) => {
      state.loading = false;
      state.record =  {};
      // state.keyword = ''
    })

  },
});

const { reducer, actions } = userSlice;

export default reducer;
