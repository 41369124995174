import React from "react";
import { Input, Col, Row } from "antd";
export default function Search(props) {
  const { placeholder, onChangeSearch } = props;
  const onSearch = (value) => {
    onChangeSearch(value);
  };

  return (
    <div>
      <Col span={24}>
        <Row>
          <Input.Search
            onSearch={onSearch}
            className="search-btn"
            placeholder={placeholder}
          />
        </Row>
      </Col>
    </div>
  );
}
