import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "../../../serviecs/auth.serviec";
import { setMessage } from "../message/index";

const admin = localStorage.getItem("Bearer");
export const adminLogin = createAsyncThunk(
  "auth/login",
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await login({ username, password });
      if (data) {
        localStorage.setItem(data.type, `${data.type} ${data.jwt}`);
      }
      return { admin: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const Logout = createAsyncThunk("auth/logout", async () => {
   localStorage.removeItem("Bearer");
});
const initialState = {
  isLoggedIn: admin ? true : false,
  admin: admin,
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [adminLogin.pending]: (state) => {
      state.loading = true;
    },
    [adminLogin.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.admin = action.payload.admin;
      state.loading = false;
    },
    [adminLogin.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.admin = null;
      state.loading = false;
    },
    [Logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.admin = null;
    },
  },
});
const { reducer } = authSlice;
export default reducer;
