import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./modules/auth/index";
import patientReducer from "./modules/patient/index";
import userReducer from "./modules/user/index";
import messageReducer from "./modules/message/index";
import headerReducer from "./modules/header/index";
const reducer = {
  auth: authReducer,
  message: messageReducer,
  patient: patientReducer,
  user:userReducer,
  header:headerReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});
export default store;
