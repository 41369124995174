import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCheckCard
} from "../../../serviecs/user.serviec";
import { setMessage } from "../message/index";
export const getCard = createAsyncThunk(
  "user/getCard",
  async (request, thunkAPI) => {
    try {
        const data = await getCheckCard(request);
      return {
        record : data,
        keyword : request,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.error) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const medicalHistories = [
  { value: "LONG_BLEEDING", lable: "Chảy máu lâu" },
  { value: "DIABETES", lable: "Tiểu đường" },
  { value: "DRUG_ALLERGY", lable: "Dị ứng thuốc" },
  { value: "INFECTIOUS_DISEASES", lable: "Truyền nhiễm" },
  { value: "HIGH_BLOOD_PRESSURE", lable: "Huyết áp cao" },
  { value: "LOW_BLOOD_PRESSURE", lable: "Huyết áp thấp" },
  { value: "MATERNITY", lable: "Thai sản" },
  { value: "RHEUMATISM", lable: "Thấp khớp" },
  { value: "HEART_DISEASE", lable: "Tim" },
  { value: "STOMACH_DISEASES", lable: "Dạ dày" },
  { value: "LIVER_DISEASES", lable: "Gan" },
  { value: "LUNG_DISEASES", lable: "Phổi" },
  { value: "NOMAL", lable: "Bình thường" },
];
