import api from "../axios/index";
export const getCheckCard = (text)=>{
   return api.get(`/insurance/check/${text}`);
}
export const getClinic = ()=>{
    return api.get(`/insurance/clinic-enum`);
}
export const getRestoration = ()=>{
    return api.get(`/insurance/restoration-enum`);
}
export const getOrigins = () =>{
    return api.get(`/insurance/origin-enum`);
}
export const getRandCode = (id)=>{
     return api.get(`/insurance/randcard?clinic=${id}`);
}
export const getAvailableCode = (code)=>{
     return api.get(`insurance/check-available?code=${code}`);
}