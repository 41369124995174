import React, { useEffect } from "react";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Image
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { adminLogin } from "../store/modules/auth";
import { Redirect } from "react-router-dom";
import { clearMessage } from "../store/modules/message";
import signinbg from "../assets/images/img-signin.jpg";
import Logo from '../assets/images/logo.png'

const { Title } = Typography;
const { Content } = Layout;
const SignIn = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, loading } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch,]);

  const onFinish = (values) => {
    const { username, password } = values;
    dispatch(adminLogin({ username, password }));
  };

  const onFinishFailed = (errorInfo) => {
  
  };
  if (isLoggedIn) {
    return <Redirect to="/user" />;
  }
  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-12">Quản lí thẻ bảo hành</Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                 <img src={Logo} alt="" />
                <Form.Item
                  className="username"
                  label="Tên đăng nhập"
                  name="username"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Mật khẩu"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Mật khẩu" />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={loading}
                    className="primary-type"
                  >
                    Đăng nhập
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            {/* <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signinbg} alt="" />
            </Col> */}
          </Row>
        </Content>
      </Layout>
    </>
  );
};
export default SignIn;
