import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import User from "./pages/patient/index";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/main.scss";
import "./assets/styles/responsive.css";
import "./assets/styles/footer.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Loadable from "react-loadable";
import Loading from "./components/Loading/index";
import Footer from "./components/layout/Footer";

const Brand = Loadable({
  loader: () => import("./pages/Brand/index"),
  loading: Loading,
});
function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn } = useSelector((state) => state.auth);
  
  useEffect(() => {}, [isLoggedIn, dispatch,]);
  if (!isLoggedIn) {
    history.push("/");
  } else {
    history.push("/user");
  }
  return (
    <div className="App">
      <Switch>
        <Route path="/sign-in" exact component={SignIn} />
        <Route
          path="/user"
          key="/user"
          exact
          render={() => {
            return (
              <Main>
                <User />
              </Main>
            );
          }}
        />
        <Route
          path="/"
          key="/brand"
        
          render={() => {
            return (
              <Main>
                   <Brand />
              </Main>
            );
          }}
        />
      </Switch>
      <Footer/>
    </div>
  );
}

export default App;
