import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Row, Col } from "antd";
import moment from "moment";
import { DeleteOutlined, EditFilled, FilterFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getList, } from "../../store/modules/patient/actions";
import {
  addPatient,
  editPatient,
  filterKeyword,
} from "../../store/modules/patient/index";
import {
  deletePatient
} from "../../serviecs/patient.serviec";
import { setTitleHeader } from "../../store/modules/header/index";
import { createColumnNoActive,cc_format } from "../../utils/index";
import Table from "../../components/Table";
import Search from "../../components/Function/Search";
import { unwrapResult } from "@reduxjs/toolkit";
import PatientForm from "./PatientForm";
import MyPagination from "../../components/Table/Pagination";

export default function Patient() {
  const dispatch = useDispatch();
  const { records, loading, page, total, pageSize, keyword, isShowForm } =
    useSelector((state) => state.patient);
  function handleClick(item) {
      dispatch(editPatient(item));
    }
  const columnsTick = [
    createColumnNoActive("Mã số thẻ", "code", "code", (item) => {
      return <span>{cc_format(item)}</span>;
    },"center"),
    createColumnNoActive("Họ Tên", "name", "name", "","center"),
    createColumnNoActive("Nha khoa", "clinic", "clinic",(item) => {
      return <span>{item.name}</span>;
    }, "center"),
    createColumnNoActive(
      "Hạn bảo hành",
      "expiredDate",
      "expiredDate",
      (item) => {
        return <span>{moment(item).format("MM/YYYY")}</span>;
      },
      "center"
    ),
    createColumnNoActive(
      "Labo",
      "labo",
      "labo",
      "",
      "center"
    ),
    createColumnNoActive("Số lượng", "numberOfTeeth", "numberOfTeeth","",'center'),
    createColumnNoActive("Vị trí", "teeth", "teeth","","center" ),
    createColumnNoActive("Xuất xứ", "origin", "origin",(item) => {
      return <span>{item.name}</span>},"center"),
    createColumnNoActive("Màu răng", "color", "color", "","center"),
    createColumnNoActive(
      "Thao tác",
      null,
      "action",
      (item) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button key="1" onClick={() => handleClick(item)}>
              <EditFilled />
              Sửa
            </Button>
            <Popconfirm
              title="Xoá chủ đề này?"
              onConfirm={() => confirmStatus(item.id)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
              placement="left"
            >
              <Button key="3" type="primary" danger>
                <DeleteOutlined className="antd-icon" />
                Xoá
              </Button>
            </Popconfirm>
          </div>
        );
      },
      "center"
    ),
  ];
  const [columns, setColumns] = useState([]);
  async function confirmStatus(id) {
        const res = await  deletePatient(id);
         if(res){
             getData();
         }
  }
  const cancel = () => {};
  const getData = (values = {}) => {
    unwrapResult(dispatch(getList({ page: 1, pageSize: pageSize, keyword: keyword,listFilter: values })));
  };
  useEffect(() => {
    dispatch(setTitleHeader("Quản lí khách hàng"));
    setColumns(columnsTick);
    getData();
  }, [dispatch]);
  const onChange = (page) => {
    dispatch(getList({ page: page, pageSize: 5 }));
  };
  const onChangeSearch = (key) => {
    dispatch(filterKeyword(key));
    // getData();
    unwrapResult(dispatch(getList({ page: 1, pageSize: 5, keyword: key })));
  };
  return (
    <div>
      <Row>
        <Col span={24}>
          <div className="flex-table">
            <div> <Search
              onChangeSearch={onChangeSearch}
              placeholder="Tìm theo mã hồ sơ, số điện thoại, họ tên"
            /></div>
            <Button
              onClick={() => {
                dispatch(addPatient());
              }}
              type="primary"
            >
              Thêm khách hàng
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="box-filter">
        <Col span={24}>
          <Table
            trueClick="onClick"
            loading={loading}
            columns={columns}
            data={records}
          />
        </Col>
        <Col span={24}>
          <MyPagination
            page={page}
            pageSize={pageSize}
            total={total}
            onPagination={onChange}
          />
        </Col>
      </Row>
      {isShowForm ? <PatientForm /> : null}
    </div>
  );
}
