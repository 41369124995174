import React, { useEffect, useState } from "react";
import { Table } from "antd";
export default function TableList(props) {
  const { columns, data, loading, trueClick = "onClick" } = props;
  const [list, setList] = useState([]);
  useEffect(() => {
    if (!loading && data.length > 0) {
      setList(
        data.map((item) => {
          return {
            ...item,
            key: item.id,
          };
        })
      );
    } else {
      setList([]);
    }
  }, [data]);
  const routerPush = (item) => {
    if (trueClick !== "onClick") return;
  };
  return (
    <div className="table-responsive">
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (event && event.target.className === "ant-table-cell") {
                routerPush(record);
              } else {
                return;
              }
            }, // click row
          };
        }}
        loading={loading}
        className="ant-border-space"
        columns={columns}
        dataSource={list}
        pagination={false}
      />
    </div>
  );
}
