
function Footer() {

  return (
    <div>
      <div id="ega-footer" className="ega-footer">
        <div className="color-overlay"></div>
        <div className="footer-con container">
          <div className="row">
            <div className="col-sm-3">
              <a href="/">
                <img
                  alt="footer logo"
                  className="img-responsive"
                  src="https://theme.hstatic.net/200000506549/1000858771/14/fot_logo.png?v=1699"
                />
              </a>
              <p>
                <h4 style={{whiteSpace: 'nowrap'}}>
                  HỆ THỐNG NHA KHOA SMILE <br /> VIỆN THẨM MỸ QUỐC TẾ
                </h4>
              </p>
              <ul className="bee-ul-social">
                <li>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/nhakhoasmile65/"
                  >
                    <span className="bee-fb-icon"></span>
                  </a>
                </li>

                <li>
                  <a target="_blank" href="https://www.nhakhoasmile.vn/ ">
                    <span className="bee-tw-icon"></span>
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCt6LhKUdYPkR0VgFdzQbC5A"
                  >
                    <span className="bee-yt-icon"></span>
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-sm-5 menu-col">
              <div className="footer-list">
                <h4 className="ega-ft-toggle-xs">
                  <span
                    style={{fontSize: '13px'}}
                    className="glyphicon glyphicon-plus ega-visible-xs-inline-block"
                  ></span>
                </h4>
                <ul className="ega-list-menu"></ul>
              </div>
              <div className="footer-list">
                <h4 className="ega-ft-toggle-xs">
                  <span
                    style={{fontSize: '13px'}}
                    className="glyphicon glyphicon-plus ega-visible-xs-inline-block"
                  ></span>
                </h4>
                <ul className="ega-list-menu"></ul>
              </div>

              <div className="footer-list">
                <h4 className="ega-ft-toggle-xs">
                  <span
                    style={{fontSize: '13px'}}
                    className="glyphicon glyphicon-plus ega-visible-xs-inline-block"
                  ></span>
                </h4>
                <ul className="ega-list-menu"></ul>
              </div>
              <div className="footer-list">
                <h4 className="ega-ft-toggle-xs">
                  <span
                    style={{fontSize: '13px'}}
                    className="glyphicon glyphicon-plus ega-visible-xs-inline-block"
                  ></span>
                </h4>
                <ul className="ega-list-menu" data-show="c3"></ul>
              </div>
            </div>
            <div className="col-sm-4 address-col">
              <h4 className="hidden-xs">LIÊN HỆ</h4>
              <address>
                <p>
                  <img src="//theme.hstatic.net/200000506549/1000858771/14/address-icon.png?v=1699" />
                  <span>
                    95, Trần Văn Khéo, P. Cái Khế, Q. Ninh Kiều,TP. Cần Thơ
                  </span>
                </p>

                <p>
                  <img src="//theme.hstatic.net/200000506549/1000858771/14/address-icon.png?v=1699" />
                  <span>PG1 - 05 Vincom, Lê Duẩn, Phường 1, TP Cà Mau</span>
                </p>

                <p>
                  <img src="//theme.hstatic.net/200000506549/1000858771/14/address-icon.png?v=1699" />
                  <span>125 Hòa Bình, Phường 7, TP Bạc Liêu</span>
                </p>

                <p>
                  <img src="//theme.hstatic.net/200000506549/1000858771/14/address-icon.png?v=1699" />
                  <span>
                    Số 03 KDT Phú Cường, Phan Thị Ràng, P. An Hòa, TP Rạch Giá
                  </span>
                </p>
                <p>
                  <img
                    src="//theme.hstatic.net/200000506549/1000858771/14/fot_c4_icon_email.png?v=1699"
                    alt="icon_email"
                  />
                  <a href="mailto:hethongnhakhoasmile.cskh@gmail.com">
                    hethongnhakhoasmile.cskh@gmail.com
                  </a>
                </p>
              </address>
            </div>
          </div>
        </div>
      </div>
      <footer className="ega-footer-copyright">
        <div className="container">
          <div className="row">
            <div style={{  display: 'flex',alignItems: 'center'}} className="col-xs-12 col-md-5 ega-footer-cp-right">
              <h5 className="ega-cpyright">
                <div className="inline-dsk">
                  © Bản quyền thuộc về nhakhoasmile.vn
                </div>
              </h5>
            </div>
            <div className="col-md-7 hidden-xs text-center">
              <ul className="nav navbar-nav">
                <li>
                  <a href="/search">Tìm kiếm</a>
                </li>

                <li>
                  <a href="/pages/about-us">Giới thiệu</a>
                </li>

                <li>
                  <a href="/pages/chinh-sach-bao-mat">Chính sách bảo mật</a>
                </li>

                <li>
                  <a href="/pages/dieu-khoan-dich-vu">Điều khoản dịch vụ</a>
                </li>

                <li>
                  <a href="/pages/lien-he">Liên hệ</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
