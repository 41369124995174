import api from "../axios/index";
import moment from "moment";
import { isEmpty } from "lodash";
function getUnixTimeStamp(date) {
  return moment(date).valueOf();
}
export const getAllPage = async (request) => {
  const { page = 1, pageSize = 20, keyword = "", listFilter = {} } = request;
  const params = {};
  if (keyword) {
    params.keyWord = keyword;
  }
  if (pageSize) {
    params.pageSize = pageSize;
  }
  if (page) {
    params.page = page;
  }
  if (!isEmpty(listFilter)) {
    const {
      created = [],
      date = [],
      treatmentDate = [],
      doctor = "",
      patientGroupId = "",
      patientSourceId = "",
      treatmentGroup = "",
      treatmentStatus = null,
    } = listFilter;
    if (!isEmpty(created)) {
      params.fromCreatedDate = getUnixTimeStamp(created[0]);
      params.toCreatedDate = getUnixTimeStamp(created[1]);
    }
    if (!isEmpty(date)) {
      params.fromDate = getUnixTimeStamp(date[0]);
      params.toDate = getUnixTimeStamp(date[1]);
    }
    if (!isEmpty(treatmentDate)) {
      params.fromTreatmentDate = getUnixTimeStamp(treatmentDate[0]);
      params.toTreatmentDate = getUnixTimeStamp(treatmentDate[1]);
    }
    if (doctor) {
      params.doctor = doctor;
    }
    if (patientGroupId) {
      params.patientGroupId = patientGroupId;
    }
    if (patientSourceId) {
      params.patientSourceId = patientSourceId;
    }
    if (treatmentGroup) {
      params.treatmentGroup = treatmentGroup;
    }
    if (treatmentStatus === true) {
      params.treatmentStatus = treatmentStatus;
    }
    if (treatmentStatus === false) {
      params.treatmentStatus = treatmentStatus;
    }
  }
  return api.get(`/insurance/`, {
    params,
  });
};
export const createPatient = (params) => {
   params.expiredDate = getUnixTimeStamp(params.expiredDate)
  return api.post(`/insurance`, params);
};
export const deletePatient = (id) => {
 return api.delete(`/insurance/${id}`);
};
export const getPatientId = (id) => {
  return api.get(`/patients/${id}`);
};
export const updatePatient = (id, params) => {
  params.expiredDate = getUnixTimeStamp(params.expiredDate)
  const data = {
    ...params,
    id: id,
  };
  return api.post(`/insurance`, data);
};
export const getPatientSource = () => {
  return api.get(`/patient_sources`);
};
export const getPatientGroup = () => {
  return api.get("/patient_groups");
};
export const getPatientPromotion = () => {
  return api.get("/promotion_groups");
};
export const getBranches = () => {
  return api.get("/branches");
};
///treatment_groups
export const getTreatmentGroups = () => {
  return api.get("/treatment_groups");
};

export const getTreatmentGroupsItems = () => {
  return api.get("/treatment_group_items");
};

/// treatments info
export const getTreatmentInfo = (idPatient) => {
  return api.get(`/patients/${idPatient}/treatments`);
};

export const createTreatmentInfo = (idPatient) => {
  return api.post(`/patients/${idPatient}/treatments`);
};

export const updateTreatmentInfo = (idPatient, params) => {
  return api.put(`/patients/${idPatient}/treatments`, params);
};

export const deleteTreatmentInfo = (idPatient, idTreatment) => {
  return api.delete(`/patients/${idPatient}/treatments/${idTreatment}`);
};
/// treatment plans to treatment
export const createTreatmentsPlans = (idPatient) => {
  return api.post(`/patients/${idPatient}/treatmentsPlans`);
};
export const getListDoctor = () => {
  return api.get('/doctors');
  //return api.get("/users");
};
