import React, {useState, useEffect} from "react";
import Logo from '../../assets/images/logo.png'
import {
    Row,
    Col,
    Button,
    Input,
    Form,
} from "antd";

import {
    SearchOutlined,
    LoadingOutlined
} from "@ant-design/icons";

import {useDispatch, useSelector} from "react-redux";
import {getCard} from "../../store/modules/user/actions"
import {Logout} from "../../store/modules/auth";
import {Route, useHistory,useLocation,Redirect} from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import SignIn from "../../pages/SignIn";

const profile = [
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        key={0}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
            fill="#111827"
        ></path>
    </svg>,
];

function Header() {
    const {title} = useSelector((state) => state.header);
    const {keyword} = useSelector((state)=>state.user);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const location  = useLocation();	
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [loading,setLoading] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
        setSearchText(keyword);
    }, [dispatch, title,isLoggedIn,keyword]);

    const routeChange = (path = "/") => {
         history.push(path);
    }
    const searchKeyWord = async ()=>{
        const text = searchText.trim().replaceAll(" ",'');
        if(text.length === 0) return;
        setLoading(true);
        try {
            const res  = unwrapResult(await dispatch(getCard(text)))
                    if(res){
                        setTimeout(()=>{
                        setLoading(false)
                        history.push("/")
                        },1000)
            } 
        } catch (error) {
            setLoading(false); 
        }
    }
    return (
        <>
            <Route path="/sign-in" exact component={SignIn} />
            <Row gutter={[24, 0]}>
                <Col span={24} md={6}>
                    <div className="ant-page-header-heading">
            <span
                className="ant-page-header-heading-title"
                style={{textTransform: "capitalize",color:'#fff'}}
            >
               <img
                   alt=""
                   src={Logo}
                   width="100"
                   height="auto"
                   className="d-inline-block align-middle fw-bold"
               />{' '}
                Bảo hành
            </span>
                    </div>
                </Col>
                <Col span={24} md={18} className="header-control">
                    {isLoggedIn ? 
                    <Button onClick={()=>{dispatch(Logout())}} className="btn-outline-primary mr-5">
                        {profile}
                        <span>Đăng xuất</span>
                    </Button>
                    :
                    <Button onClick={()=> routeChange(`/sign-in`)} className="btn-outline-primary mr-5">
                        <span>Đăng nhập</span>
                    </Button>}
                    {isLoggedIn && location.pathname !== '/user' ? 
                     <Button onClick={()=> routeChange(`/user`)} className="btn-outline-primary mr-5">
                        <span>Danh sách khách hàng</span>
                    </Button>:''}
                    {/* <Button loading={loading} onClick={() => {
                      searchKeyWord();
                    }} className="ant-btn-primary mr-5">
                        <SearchOutlined/>
                        <span >Tra cứu</span>
                    </Button> */}
                    {keyword || (isLoggedIn && location.pathname === '/user') ?
                     <div className="ant-btn-primary mr-5 mobile-search">
                     <Input.Search  enterButton={<div   onClick={()=>searchKeyWord()}
                      >         {loading ? <LoadingOutlined></LoadingOutlined> : <SearchOutlined/>  }
                                 <span >Tra cứu</span>
                      </div>}  
                      value={searchText} 
                      onChange={(e)=> setSearchText(e.target.value)} 
                      onPressEnter={searchKeyWord} placeholder="Tra cứu ..."/>
                     </div>
                    :<></>}
                    
                </Col>
            </Row>
        </>
    );
}

export default Header;