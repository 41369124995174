import React, { useEffect, useState,useRef } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  message,
  Input,
  Button,
  DatePicker,
  Select,
  Divider,
  Space
} from "antd";
import {PlusOutlined} from "@ant-design/icons"
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
// import frontCard from "../../assets/images/warrantycard-1.png";
import frontCard from "../../assets/images/warrantycard-1.png"
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { closeForm } from "../../store/modules/patient/index";
import { getList  } from "../../store/modules/patient/actions";
import {
  createPatient,
  updatePatient,
} from "../../serviecs/patient.serviec";
 import {
   getClinic,
   getOrigins,
   getRestoration,
   getRandCode,
   getAvailableCode
 } from "../../serviecs/user.serviec";
import { cc_format } from "../../utils/index";
import {Card} from "react-bootstrap";

import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
export default function PatientForm() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { isShowForm, record, page, pageSize } = useSelector(
    (state) => state.patient
  );
  const [code, setCode]  = useState('');
  const [name, setName] = useState('');
  const [nameRestoration, setNameRestoration] = useState('');
  const [clinic, setClinic] = useState('');
  const [restoration, setRestoration] = useState('');
  const [expiredDate,setExpiredDate] = useState('');
  const [listClinic, setListClinic] = useState([]);
  const [listOrigins, setListOrigins] = useState([]);
  const [listRestoration, setListRestoration] = useState([])
  const [checkCard,setCheckCard] = useState(false);
  const dateFormat = "MM/YYYY";

  const [isEdit, setIsEdit] = useState(false);
  const getSource = async () => {
    try {
         const res = await getClinic();
         setListClinic(res);
         const res1 = await getOrigins();
         setListOrigins(res1);
         const res2 = await getRestoration();
         setListRestoration(res2);
         if (record.id) {
          form.setFieldsValue({
            "code": record.code,
            "name": record.name,
            "restoration": record.restoration,
            "clinic": record.clinic?.id,
            "expiredDate": moment(record.expiredDate),
            "labo": record.labo,
            "teeth": record.teeth,
            "origin": record.origin?.id        ,
            "numberOfTeeth": record.numberOfTeeth,
            "color": record.color
          });
           setCode(record.code);
           setName(record.name);
           setClinic(record.clinic?.id);
           setExpiredDate(record.expiredDate);
           setRestoration(record.restoration);
        } else {
          form.setFieldsValue({
            "labo": 'NEWWORLD'
          })  
        }
    }
    catch (e){

    }
   
  };
  useEffect(() => {
    form.resetFields();
    setCheckCard(false);
    getSource();
    setIsEdit(record.id ? true : false);
  }, [dispatch, record]);

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        const res = !isEdit
          ? await createPatient(values)
          : await updatePatient(record.id, values);
        try {
          message.success(
            !isEdit
              ? "Tạo thành công khách hàng mới"
              : "Chỉnh sửa thành công khách hàng"
          );
          unwrapResult(dispatch(getList({ page: page, pageSize: pageSize })));
        } catch (error) {}
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    dispatch(closeForm());
  };
  function customButtomFooter() {
    return (
      <div className="footer-btn">
        <Button onClick={handleOk} className="violet">
          Lưu thông tin
        </Button>
        <Button onClick={handleCancel} className="gray">
          Huỷ
        </Button>
      </div>
    );
  }

  const getId = () => uuidv4();
  const randCode = async (id)=>{
        const rs = await getRandCode(id);
        if(rs){
           form.setFieldsValue({
             code: rs.message,
           })
           setCode(rs.message);
        }
  }
  const onNameChange = (event) => {
    setNameRestoration(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    if(nameRestoration !== ''){
        // setItems([...items, name || `New item ${index++}`]);
        setListRestoration([...listRestoration,{id: getId() , name: nameRestoration }])
        form.setFieldsValue({
          "restoration": nameRestoration,
        });

    }
    
    setNameRestoration('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Modal
      title={isEdit ? "Chỉnh sửa thông tin khách hàng" : "Thêm khách hàng mới"}
      visible={isShowForm}
      onCancel={handleCancel}
      width={800}
      footer={customButtomFooter()}
    >
      <Form form={form}>
      <Row className="justify-content-md-center card-form">
                    <Col xs lg="5">
                        <Card>
                            <Card.Img src={frontCard} alt="Mặt trước"/>
                            <Card.ImgOverlay>
                                  <div className="poset">
                                    <div className="pos-clinic">
                                        <span>{clinic}</span>
                                    </div>
                                    <div className="pos-name">
                                        <span>{name}</span>
                                    </div>
                                    <div className="pos-restoration">
                                        <span>{restoration}</span>
                                    </div>
                                    <div className="pos-valid-to">
                                        <span>{expiredDate && moment(expiredDate).format('MM/YYYY').replace('20','')}</span>
                                    </div>
                                    <div className="pos-code">
                                        <span>{cc_format(code)}</span>
                                    </div>
                                </div>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
                    <Col md="auto"/>
            </Row>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Row gutter={[16, 16]}>
             <Col span={12}>
                <div className="input">
                  <Form.Item
                  
                    name="clinic"
                    label="Nha khoa"
                    rules={[
                      { required: true, message: "Không được để trống nha khoa" },
                    ]}
                    
                  >
                    {/* <Input onChange={(e)=>setClinic(e.target.value)} /> */}
                    <Select   showSearch 
                      optionFilterProp="children"
                      onChange={(e)=> {
                       const temp =  listClinic.filter((item)=> item.id === e);
                       if(temp){
                            setClinic(temp[0].name);
                            randCode(temp[0].id);
                       }
                    } }
                    filterOption={(input, option) =>
                     { 
                       return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    }
                    options={listClinic.map((item)=>{
                       return{
                          value: item.id,
                          label: item.name,
                          key:item.id
                       }
                    })}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="input">
                  <Form.Item
                    name="code"
                    label="Mã số thẻ"
                    rules={[
                      {
                        required: true,
                        message: "Mã số thẻ không được để trống",
                      },
                      ({ getFieldValue }) => ({
                        async validator(_, value) {
                          if(value.length < 7) return Promise.resolve();
                          else if(value === code) return Promise.resolve();
                            try {
                                const res = await getAvailableCode(value);
                                 if(res){
                                   setCode(value); 
                                 }
                                return Promise.resolve()
                            } catch (error) {
                                if(error.response.status === 400 && error.response.data.message === null){
                                     return Promise.reject(new Error('Mã Code bị trùng đề nghị nhập lại'));
                                }
                            }
                        },
                      })
                    ]}
                  >
                    <Input maxLength={7} onChange={(e)=> {
                      if(e.target.value.length < 2){
                          form.setFieldsValue({
                             code:code,
                          })
                          setCode(code);
                      }
                      else setCode(e.target.value)
                       
                    }} />
                  </Form.Item>
                </div>
              </Col>
            
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="input">
                  <Form.Item
                    name="name"
                    label="Họ tên"
                    rules={[
                      { required: true, message: "Không được để trống họ tên" },
                    ]}
                  >
                    <Input onChange={(e)=>setName(e.target.value)} />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="input">
                  <Form.Item
                    name="expiredDate"
                    label="Ngày hết hạn"
                    rules={[
                      {
                        required: true,
                        message: "Ngày hết hạn không được để trống",
                      },
                    ]}
                  >
                    <DatePicker picker="month" onChange={(value, dateString) => setExpiredDate(value) } locale={locale} format={dateFormat} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="input">
                  <Form.Item
                    name="labo"
                    label="Labo"
                    rules={[
                      { required: true, message: "Không được để trống labo" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="input">
                  <Form.Item
                    name="numberOfTeeth"
                    label="Số lượng"
                    rules={[
                      { required: true, message: "Không được để trống số lượng" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="input">
                  <Form.Item
                    name="teeth"
                    label="Vị trí"
                    rules={[
                      { required: true, message: "Không được để vị trí" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="input">
                  <Form.Item
                    name="restoration"
                    label="Loại phục hình"
                    rules={[
                      { required: true, message: "Không được để loại phục hình" },
                    ]}
                  >
                    {/* <Input onChange={(e)=> setRestoration(e.target.value)} /> */}
                    <Select onChange={(e)=> {
                        const temp =  listRestoration.filter((item)=> item.name === e);
                        if(temp){
                               setRestoration(temp[0].name);
                        }
                        }}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: '8px 0',
                              }}
                            />
                            <Space
                              style={{
                                padding: '0 8px 4px',
                              }}
                            >
                              <Input
                                placeholder="Thêm thù hình"
                                ref={inputRef}
                                value={nameRestoration}
                                onChange={onNameChange}
                                onPressEnter={addItem}
                              />
                               <Button  type="primary" icon={<PlusOutlined />} onClick={addItem}>
                                  Thêm thù hình
                                </Button>
                            </Space>
                          </>
                        )} 
                      >
                       {
                          listRestoration.map((item)=>{
                              return <Select.Option key={item.id} value={item.name}>
                                {item.name}
                              </Select.Option>
                          })
                       }
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="input">
                  <Form.Item
                    name="origin"
                    label="Xuất xứ"
                    rules={[
                      { required: true, message: "Không được để trống xuất xứ" },
                    ]}
                  >
                     {/* <Input /> */}
                     <Select>
                       {
                          listOrigins.map((item)=>{
                              return <Select.Option key={item.id} value={item.id}>
                                {item.name}
                              </Select.Option>
                          })
                       }
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="input">
                  <Form.Item
                    name="color"
                    label="Màu răng"
                    rules={[
                      { required: true, message: "Không được để trống màu răng" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
